import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import SignupPage from './SignUpPage';
import './App.css';
import MainPageMobile from './MainpageMobile';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [deviceType, setDeviceType] = useState('mobile');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/android|iphone|ipad|ipod/.test(userAgent)) {
      setDeviceType('mobile');
    } else {
      setDeviceType('desktop');
    }
  }, []);


  return (
    <Routes>
      <Route path="/" element={deviceType === "mobile" ? <MainPageMobile /> : <MainPage />} />
      <Route path="/signup" element={<SignupPage />} />
    </Routes>
  );
}

export default App;
