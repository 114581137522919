import React, { useRef, useEffect, useState } from 'react';
import './MainPage.css';
import alamarLogo from './images/Alamar_WordMark.svg';
import backgroundVideo from './images/dekstop.mov';
import whiteLogo from './images/white_logo.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';

function MainPage() {
  const videoRef = useRef(null);
  const signupRef = useRef(null);
  const mainPageRef = useRef(null);
  const [email, setEmail] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  const apiUrl = 'https://b0ra4lny8a.execute-api.us-east-2.amazonaws.com/api/welcome';
  const apiKey = 'WKeSaHTSGoaimwktekdLb1Y4GxUrSCm8e2d9R51e';

  const handleScrollTo = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    const mainPage = mainPageRef.current;

    const handleVideoEnd = () => {
      if (!isScrolling) {
        handleScrollTo(signupRef);

        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.pause();
        }
      }
    };

    const handleVideoPlay = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && video && !isScrolling) {
        video.play().catch((error) => {
          console.error("Video playback blocked, muting video:", error);
        });
      }
    };

    if (video) {
      video.muted = isMuted;
      video.play().catch((error) => {
        console.error("Video playback blocked, muting video:", error);
      });

      video.addEventListener('ended', handleVideoEnd);
    }

    const observer = new IntersectionObserver(handleVideoPlay, {
      root: null,
      threshold: 0.5,
    });

    if (mainPage) {
      observer.observe(mainPage);
    }

    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnd);
      }
      if (mainPage) {
        observer.unobserve(mainPage);
      }
    };
  }, [isScrolling, isMuted]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const handleClick = () => {
    if (validateEmail(email)) {
      mailChimp(email);
    } else {
      setSendEmail(false);
    }
  };

  const mailChimp = async (email) => {
    try {
      const response = await axios.post(apiUrl,
        {
          "email": email
        },
        {
          headers: {
            'x-api-key': `${apiKey}`
          }
        }
      );
      if (response.data.statusCode === 409) {
        setEmailValidation(true);
      }
      setSendEmail(true);
      setEmail('');

    } catch (error) {
      setSendEmail(false);
      setEmail('');
    }
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; 
      videoRef.current.play();
    }
  };

  return (
    <section className="App">
      <section className="main-page" id="main-page" ref={mainPageRef} data-index="1">
        <header className="App-header">
          <div className="room-container">
            <video
              autoPlay
              webkit-playsinline="true"
              muted={isMuted}
              loop={false}
              playsInline
              preload="auto"
              className="room-image"
              ref={videoRef}
            >
              <source src={backgroundVideo} type="video/mp4" />
            </video>
            <button className="mute-button" onClick={toggleMute}>
              {isMuted ? (
                <i className="fas fa-volume-mute"></i>
              ) : (
                <i className="fas fa-volume-up"></i>
              )}
            </button>
          </div>

          <button
            className="signup-button no-underline"
            onClick={() => {
              setIsScrolling(true);
              pauseVideo();
              handleScrollTo(signupRef);
              setIsScrolling(false);
            }}
          >
            SIGN UP 
            {/*<i className="fas fa-arrow-down"></i>*/}
          </button>
        </header>
      </section>

      {/* Signup Page Section */}
      <section className="signup-page" id="signup-page" ref={signupRef} data-index="2">
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
          <img src={alamarLogo} alt="Logo" style={{ height: '20%', width: '20%', cursor:'pointer' }}  onClick={() => {
              setIsScrolling(true);
              playVideo(); // Play video when "Up Arrow" button is clicked
              handleScrollTo(videoRef);
              setIsScrolling(false);
            }}/>
        </div>



        {sendEmail ?
          <div className='thankyou'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '24px', justifyContent: 'end' }}>
              {emailValidation ?
                <span style={{ display: 'flex', justifyContent: 'center', }}>You are already signed up!</span> :
                <span style={{ fontSize: '22px', display: 'flex', justifyContent: 'center', }}>Thank you for signing up!</span>
              }
              <img src={whiteLogo} alt='logo' style={{ width: '100%', height: '30%' }}  />
            </div>
          </div>
          :
          <div className="email-container">
            <div className="email-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
              <span className="email-title">Sign up for product drop dates</span>
              <input
                type="email"
                placeholder="Email"
                value={email}
                className="email-input-field"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        }

        <div className="up-container">
          <div className="instagram">
            <a href="https://www.instagram.com/alamar.designs/" target="_blank" rel="noopener noreferrer" >
              INSTAGRAM
            </a>
          </div>
          {/* <button
            onClick={() => {
              setIsScrolling(true);
              playVideo(); // Play video when "Up Arrow" button is clicked
              handleScrollTo(videoRef);
              setIsScrolling(false);
            }}
            className="up-button-dekstop"
          >
            <i className="fas fa-arrow-up"></i>
          </button> */}
        </div>
      </section>
    </section>
  );
}

export default MainPage;
