import React from 'react';
// import { useNavigate } from 'react-router-dom';
import '../src/SignUp.css';
// import heartPink from './images/alamar_pink_heart.png';
import alamarLogo from './images/Alamar_WordMark.svg'

import '@fortawesome/fontawesome-free/css/all.min.css';

function SignupPage() {
    // const navigate = useNavigate();

    // const handleBackToHome = () => {
    //     navigate('/');
    // };

    return (
        <div className='main'>
            <div class="logo-container">
                {/* <img src="logo.svg" alt="Logo"> */}
                <img src={alamarLogo} alt="Logo" />
            </div>

            <div class="content">
                <h3>Signup for product drop dates</h3>
                {/* <input type="text" class="text-input" placeholder="Enter your email"> */}
            </div>

            <div class="bottom-content">
                <a href="https://www.instagram.com" >Instagram</a>
                <a href="#top" class="up-arrow"><i class="fas fa-arrow-up"></i></a>
            </div>
        </div>
    )
}

export default SignupPage;
