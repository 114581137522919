import React, { useRef, useEffect, useState } from 'react';
import './MainpageMobile.css';
import backgroundMobileVideo from './images/mobile-phone.mov';
import whiteLogo from './images/white_logo.svg';
import alamarLogo from './images/Alamar_WordMark.svg'
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';

function MainPageMobile() {
    const videoRef = useRef(null);
    const signupRef = useRef(null);
    const mainPageRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);
    const [email, setEmail] = useState(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [sendEmail, setSendEmail] = useState(false)
    const [emailValidation, setEmailValidation] = useState(false);

    const apiUrl = 'https://b0ra4lny8a.execute-api.us-east-2.amazonaws.com/api/welcome';
    const apiKey = 'WKeSaHTSGoaimwktekdLb1Y4GxUrSCm8e2d9R51e';

    const handleScrollTo = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    useEffect(() => {
        const video = videoRef.current;
        const mainPage = mainPageRef.current;

        const handleVideoEnd = () => {
            if (!isScrolling) {
                handleScrollTo(signupRef);
            }
        };

        const handleVideoPlay = (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting && video && !isScrolling) {
                video.play().catch((error) => {
                    console.error("Video playback blocked, muting video:", error);
                });
            }
        };

        if (video) {
            video.muted = isMuted; // Set muted state based on isMuted
            // Play the video immediately
            video.play().catch((error) => {
                console.error("Video playback blocked, muting video:", error);
            });

            video.addEventListener('ended', handleVideoEnd);
        }

        const observer = new IntersectionObserver(handleVideoPlay, {
            root: null,
            threshold: 0.5,
        });

        if (mainPage) {
            observer.observe(mainPage);
        }

        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
            if (mainPage) {
                observer.unobserve(mainPage);
            }
        };
    }, [isScrolling, isMuted]);


    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleClick();

        }
    }
    const handleClick = () => {
        if (validateEmail(email)) {
            mailChimp(email);
        } else {
            setSendEmail(false)
        }
    };

    const mailChimp = async (email) => {
        console.log(email);
        try {
            const response = await axios.post(apiUrl,
                {
                    "email": email
                },
                {
                    headers: {
                        'x-api-key': ` ${apiKey}`
                    }
                }
            );
            if (response.data.statusCode === 409) {
                setEmailValidation(true);
            }

            setSendEmail(true);
            setEmail('');
        } catch (error) {
            console.log(error);
            setEmail('');
            setSendEmail(false);
        }
    };

    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0; 
            videoRef.current.play();
        }
    };

    const toggleMute = () => {
        const video = videoRef.current;
        if (video) {
            video.muted = !video.muted;
            setIsMuted(video.muted);
        }
    };

    return (
        <section className="App">
            <section className="main-page" id="main-page" ref={mainPageRef}>
                <header className="App-header">
                    <div className="room-container" >
                        <video
                            autoPlay
                            // muted
                            loop={false}
                            playsInline
                            preload="auto"
                            className="room-image"
                            ref={videoRef}
                        >
                            <source src={backgroundMobileVideo} type="video/mp4" />
                        </video>
                        {/* Mute Icon */}
                        <button className="mute-mobile-button" onClick={toggleMute}>
                            {isMuted ? (
                                <i className="fas fa-volume-mute"></i>
                            ) : (
                                <i className="fas fa-volume-up"></i>
                            )}
                        </button>
                    </div>

                    <button
                        className="signup-button-mobile no-underline"
                        onClick={() => {
                            setIsScrolling(true);
                            pauseVideo();
                            handleScrollTo(signupRef);
                            setIsScrolling(false);
                        }}
                    >
                        SIGN UP
                         {/* <i className="fas fa-arrow-down"></i> */}
                    </button>
                </header>
            </section>

            {/* Signup Page Section */}
            <section className="signup-mobilepage" id="signup-mobilepage" ref={signupRef}>
                <div class="logo-container-mobile">
                    <img src={alamarLogo} alt="Logo" onClick={() => {
                        playVideo();
                        handleScrollTo(videoRef)
                    }} />
                </div>
                {sendEmail ?
                    <div className='thankyoumobile'>
                        
                            {emailValidation ?
                                <span style={{ fontSize: '18px', display: 'flex', justifyContent: 'center', }}>You are already signed up!</span> :
                                <span style={{ fontSize: '18px',display: 'flex', justifyContent: 'center', }}>Thank you for signing up!</span>
                            }
                            <img src={whiteLogo} alt='logo' style={{ width: '100%', height: '18%' }} />
                        
                    </div>
                    :
                    <div class="content">
                        <span style={{ fontSize: '18px' }}>Signup for product drop dates</span>
                        <input
                            type="email"
                            placeholder="Email"
                            className='text-input'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown} />
                    </div>
                }
                <div class="bottom-content">
                    <a
                        href="https://www.instagram.com/alamar.designs/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        INSTAGRAM
                    </a>
                    {/* <button class="up-arrow" onClick={() => {
                        playVideo();
                        handleScrollTo(videoRef)
                    }}><i class="fas fa-arrow-up"></i></button> */}
                    {/* <button onClick={() => {
                        playVideo();
                        handleScrollTo(videoRef)
                    }}
                        className="up-button">
                        <i className="fas fa-arrow-up"></i>
                    </button> */}
                </div>
                {/* <div className="signup-container" >
                    <img src={alamarLogo} alt="Logo" style={{ height: '40%', width: '40%', display: 'flex', justifyContent: 'center', margin: '0px 0px 0px 100px' }} />
                </div>
                {sendEmail ?
                    <div className='email-container'>
                        <div className="email-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {emailValidation ?
                                <span style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', }}>You are already signed up!</span> :
                                <span style={{ fontSize: '14px' }}>Thank you for signing up!</span>
                            }
                            <img src={whiteLogo} alt='logo' style={{ width: '50%', height: '50%' }} />
                        </div>
                    </div>
                    :
                    <div className='email-container'>
                        <div className="email-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center' }}>
                            <span style={{ fontSize: '18px', marginBottom: '20px', color: 'white' }}>Sign up for product drop dates</span>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={handleKeyDown} />
                        </div>
                    </div>}
                <div className="up-container-mobile">
                    <div className="footer">
                        <a
                            href="https://www.instagram.com/alamar.designs/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            INSTAGRAM
                        </a>
                    </div>
                    <button onClick={() => {
                        playVideo();
                        handleScrollTo(videoRef)
                    }}
                        className="up-button">
                        <i className="fas fa-arrow-up"></i>
                    </button>
                </div> */}
            </section>
        </section>
    );
}

export default MainPageMobile;
